.Footer {
  background-color: #0071bc;
  position: absolute;
  color: rgb(255, 220, 220);
  bottom: 0;
  padding-top: 2em;
  width: 100%;
  .social-medias{
    display: flex;
    justify-content: space-around;
    img{
      display: flex;
      margin-bottom: 40%;
      width: 50px;
      height: auto;
    }
  }
}
@media only screen and (max-width: 830px)
{
  .Footer{
    position: absolute;
    margin-top: 15%;
    .social-medias{
      img{
        width: 30px;

      }
    }
  }
}
