.Hospital {
  .tableaux {
    display: flex;
    justify-content: space-between;
  }
  .waiting {
    width: 40%;
    background-color: grey;
    margin-left: 5px;
    h4 {
      display: flex;
      justify-content: center;
    }
  }
  .accepted {
    width: 40%;
    background-color: grey;
    margin-right: 5px;
    h4 {
      display: flex;
      justify-content: center;
    }
  }
}
