.SignInHospital{
  float: right;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #0071bc;
  padding: 32;
  width: 18rem;
  height: auto;

  border-radius: 16;
  margin-top: 200px;
  form{
    
    h3{
      color: white;
      align-items: center;
      margin-left: 40px;
    }
    input{
      padding: 5px;
      margin-top: 20px;
    }
    .formPassword{
      margin-bottom: 20px; 
    }
    button{
      margin-top: 16;
      margin-left: 20px;
      margin-bottom: 10px;
      width: 70%;
      height: 30px;
      background-color: #0071bc;
      color: white;
      border-color: white;
      border-radius: 20px;
      font-weight: bold;
      font-size: 16px;
    }
  }

}

@media only screen and (max-width: 780px) {
.SignInHospital{
  margin-top: 5%;
}
}
@media only screen and (max-width: 587px) {
  .SignInHospital{
    margin-bottom: 5%;
  }
  }

  @media screen and (max-width: 989px) and (min-width: 787px)
  {
    .SignInHospital{
      margin-bottom: 10%;
      margin-top: 8%;
    }
  }