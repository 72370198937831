.nav{
  background-color: #0071bc;
  height: 70px;
  width: 100%;
  .logo{
    margin: 1% 1%;
  }
}

@media screen and (max-width: 322px)
{
  .nav{
  }
}