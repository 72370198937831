.HistoryUser{
  align-content: center;
  .title{
    display: flex;
    justify-content: center;
    width: 95%;

  }
  ul{
    flex: 1;
    display: flex;
    min-height: 350px;
    overflow-x: "scroll";
    align-items: center;
    width: 90%;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .searchByCode{
    float: right;
    display: flex;
    input{
      border-radius: 10px;
      margin-right: 10px;
    }
    button{
      margin-right: 15px;
      background-color: #0071bc;
      color: white;
      border-radius: 13px;
    }
  }
}