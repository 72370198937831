.ImageHistory{
align-items: center;
display: flex;
justify-content: center;
width: 100%;
flex-wrap: wrap;
  img{
    height: auto;  
    width: 200px;  
    flex-wrap: wrap;
    padding-left: 3px;
  }
}